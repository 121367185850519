
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import './App.css';

ReactGA.initialize('UA-214166489-1');

function App() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    async function getSeason() {
      var season = Math.floor( Math.random() * 9+1 );
      console.log('season is ' + season)
      fetch('data/season'+season+'.txt').then((response) => response.json())
      .then((json) => {
        delete json.jsonGraph.videos['70153373']
        var episodes = Object.keys(json.jsonGraph.videos);
        var ran_key = episodes[Math.floor(Math.random() *episodes.length)];
        setTitle(json.jsonGraph.videos[ran_key].title.value)
        setDescription(json.jsonGraph.videos[ran_key].synopsis.value)
        setTimeout(function(){
          ReactGA.event({category: 'Watch', action: 'Seinfeld', label: json.jsonGraph.videos[ran_key].title.value})
          window.location.href = "https://www.netflix.com/watch/"+ran_key;
        }, 10000);
      });
      //console.log('season ' + JSON.stringify(season))
    }
    getSeason();
    
  }, []);

  
  return (
    <div className="App">
      <header className="App-header">
        <img style={{maxWidth: '75%'}} src={process.env.PUBLIC_URL + "randomjerry.png"} alt="logo" />
        <div style={{width: '50%', height: '40vh', alignItems: 'left'}}>
          <p><b>{title}</b></p>
          <p style={{textAlign: 'left'}}>{description}</p>
        </div>
        <p class='saving'>
          Starting Seinfeld episode on Netflix now<span>.</span><span>.</span><span>.</span>
        </p>
      </header>
    </div>
  );
}

export default App;
